.App {
  text-align: center !important;
  font-family: 'Inter', sans-serif;
}
body {
  background-color: #0071e3 !important;
}
.Btn {
  margin: 5px;
  background-color: #0071e3 !important;
}
.Btn:hover {
  background-color: #009ee5 !important;
}
button.dropdown {
  color: gray;
  box-shadow: none !important;
}
button.dropdown:hover {
  color: black;
  outline: none;
  box-shadow: none !important;
}
.link {
  background-color: #f8f9fa !important;
  color: gray !important;
  border: none !important;
  box-shadow: none !important;
}
button.link:hover {
  color: black !important;
  box-shadow: none !important;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 50px;
}

.buttonDownload {
  border: none;
}
.hide {
  display: none;
  text-align: center;
}
.buttonDownload:hover + .hide {
  display: block;
}
.videoContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0px 150px 0;
}
.videos {
  margin: 10px;
}
/* header */
.navbar-collapse {
  margin-left: 12%;
}
.logo {
  width: 260px;
  padding: 0px 0px 0px 50px;
}
.joinTeam {
  margin-right: 50px;
}
/* footer */
.footer {
  background-color: #f8f9fa;
  padding-top: 2%;
}
.logo-side img {
  width: 65%;
}
.logo-side p {
  color: #878787;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
  padding: 22px 65px 0 0;
}
.logo-side ul {
  margin: 35px 0 0;
  padding: 0;
}
.logo-side ul li {
  list-style: none;
  padding-bottom: 15px;
}
.logo-side ul li a {
  color: #878787;
  font-size: 18px;
  font-weight: 400;
}
.logo-side ul li a i {
  color: #0193d7;
  font-size: 20px;
  transform: translateY(1px);
  padding-right: 10px;
}
.other-links h5 {
  color: #393939;
  font-size: 25px;
  font-weight: 500;
  margin: 0;
  padding-top: 20px;
}
.other-links .underline {
  width: 25%;
  height: 2px;
  background-color: #0193d7;
  margin: 10px 0 0;
}
.other-links ul {
  margin: 20px 0 0;
  padding: 0;
}
.other-links ul li {
  list-style-type: none;
  line-height: 36px;
}
.other-links ul li a {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}
.other-links ul li Link {
  color: #878787;
  font-size: 16px;
  font-weight: 400;
}
.other-links ul li h4 {
  font-size: 18px;
  color: #555555;
  font-weight: 500;
}
.other-links ul li p {
  font-size: 16px;
  color: #878787;
  padding-bottom: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
}
.bottom-other-links {
  padding: 0px 0 0;
}
.bottom-other-links p {
  color: #878787;
  font-weight: 400;
  font-size: 15px;
  padding: 0px 0 15px;
  text-transform: capitalize;
  margin: 0;
}
.bottom-other-links p a {
  color: #878787;
}
/* State Licensing */
.stateLicensingPage {
  background-color: #ffffff !important;
}
.all-caption.stateLin-text {
  padding-top: 200px;
}
.mapContainer {
  display: flex;
}
.mapContainer img {
  padding: 100px 0 0 100px;
  width: 170%;
}
.rightLeftSection {
  padding: 80px;
}
.modal-title {
  background-color: #0071e3;
  color: white;
}
.PDFModal {
  flex-direction: column;
  display: block;
}
.addBoxLis {
  width: 100%;
  background-color: #fff;
  padding: 35px;
  margin-bottom: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}
.addBoxLis h3 {
  font-size: 22px;
  font-weight: 600;
  color: #0193d7;
}
.addBoxLis p {
  color: #878787;
  line-height: 30px;
}
.double-btnText .btn {
  border-radius: 100px;
  font-size: 16px;
  padding: 10px 32px;
  width: auto;
  background-image: linear-gradient(165deg, #0071e3, #0193d7);
  color: #fff;
}
.double-btnText h3 {
  font-size: 22px;
  font-weight: 500;
  color: #393939;
  margin: 0;
}
.double-btnText h5 {
  margin: 0;
  padding: 5px 0;
  font-size: 15px;
  color: #979797;
  font-weight: 400;
  line-height: 26px;
}
.bottomLink a {
  color: #979797;
  font-size: 15px;
}
.bottomLink:hover Button {
  color: #0193d7;
}
.applyModal .close {
  top: 9%;
}
.applyModal.reqclbk .close {
  top: 10%;
}
/* responsive css 1635  */
/* Privacy Policy */
.privacyPolicyPage {
  background-color: #f4f4f4;
  padding: 80px 200px;
}

.maintbs {
  border-left: 2px solid #d1d1d1;
}
.maintbs.nav-pills .nav-link.active,
.maintbs.nav-pills .show > .nav-link {
  color: #19272e;
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
}
.maintbs.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #747d85;
  padding: 12px 10px;
  font-size: 15px;
}
/* ContactUs */
.contactUsPage {
  background-color: #f4f4f4;
  padding: 50px 200px 100px;
}

.all-caption.contactText {
  padding: 45px 0 0;
}
.all-caption.contactText p {
  font-size: 20px;
}
.contactusImage {
  width: 50%;
  margin: 10%;
}
.contacts {
  display: flex;
  text-align: center;
  margin-top: 100px;
}
.danielleImage {
  width: 35%;
}
.danielleCard {
  border-right: 1px solid #dee2e6 !important;
}
.sarahCard {
  width: 45%;
  border-right: 1px solid #dee2e6 !important;
}
.bennyCard {
  width: 45%;
}
.contactuscontent {
  text-align: center;
}
.all-caption.contactText .timeNoMail p {
  padding: 10px 0 0 14px;
  font-size: 15px;
  text-transform: inherit;
}
.freddyInfo {
  margin: 50px 0 0;
  /* border: 1px solid #e3e3e3;*/
  background: #fafafa;
  border-radius: 4px;
  padding: 10px 30px 30px;
}
.freddyInfo img {
  width: 12%;
  border-radius: 100%;
  height: 15%;
  margin-top: 15px;
  box-shadow: 0px 2px 10px #00000017;
  border: 2px solid #0193d7;
}
.textbtnarea {
  padding: 5px 0px 0 21px;
}
.all-caption.contactText {
  padding: 45px 0 0;
}
.all-caption.contactText p {
  font-size: 20px;
}
.all-caption.contactText .timeNoMail p {
  padding: 10px 0 0 14px;
  font-size: 15px;
  text-transform: inherit;
}
.freddyInfo {
  margin: 50px 0 0;
  /* border: 1px solid #e3e3e3;*/
  background: #fafafa;
  border-radius: 4px;
  padding: 10px 30px 30px;
}
.freddyInfo img {
  width: 12%;
  border-radius: 100%;
  height: 15%;
  margin-top: 15px;
  box-shadow: 0px 2px 10px #00000017;
  border: 2px solid #0193d7;
}
.textbtnarea {
  padding: 5px 0px 0 21px;
}
.all-caption .freddyInfo p {
  font-size: 12px;
  text-align: left;
  padding: 5px 0px;
}
.all-caption .freddyInfo small {
  font-size: 15px;
  display: flex;
  padding: 5px 0px 0 21px;
  font-weight: 300;
  color: #303030;
}
.all-caption .freddyInfo h5 {
  font-size: 25px;
  margin: 0;
  text-align: left;
  padding: 20px 0px 0 20px;
}
.freddycliktext {
  padding: 0 0 0 10px;
}
.freddycliktext label {
  font-size: 14px;
  width: 37%;
  text-align: left;
  color: #5b5a5a;
  margin: 0;
  padding: 0 0px 8px 5px;
}
.all-caption .freddycliktext .btn {
  font-size: 14px;
  padding: 6px 36px;
}
/* Home Pge */
.homePage {
  flex-direction: column;
  display: flex;
}
.leftDots {
  float: left;
  width: 200px;
}
.rightDots {
  float: right;
  width: 200px;
  margin-top: 25px;
}
.headers {
  color: #f6fcfe;
  text-align: center;
  padding: 50px;
}
.loginContainer {
  background-color: #f6fcfe;
  padding: 25px;
  margin-top: 45px;
  justify-content: center;
}
.hrForm {
  background-color: #f4f4f4;
  margin-top: 25px;
  padding: 75px 0 75px 0;
}
/* employee page */
.employeePage {
  padding: 50px 50px 100px 50px;
  background-color: #f4f4f4;
  display: flex;
}
.welcomeHeaders {
  margin: 7px;
  width: 35%;
  text-align: left;
  flex-direction: column;
}
.welcomeButtons {
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  text-align: center;
}

/* resource page */
.resourcePage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
}
.resourceHeaders {
  width: 22%;
}
.resourceBtns {
  justify-content: space-between;
  flex-wrap: wrap;
  width: 60%;
}

/* marketing resource page */
.marketingPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.marketingHeaders {
  width: 23%;
}
.marketingBtns {
  justify-content: space-between;
  width: 60%;
  text-align: center;
}
/* Human Resources Page */
.hrPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}

/* Mortgage Products Page */
.mortgageProductsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.mortgageProductsBtns {
  width: 70%;
  text-align: center;
}
/* Loan Officer Tools Page */
.LOToolsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Sales Page */
.salesPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Technology Page */
.technologyPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Articles Page */
.articlesPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  flex-direction: row;
  justify-content: space-between;
}

.articles {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}

/* Digital Design Templates Page */
.digitalDesignTempsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Print Design Templates Page */
.printDesignTempsPage {
  padding: 50px;
  background-color: #f8f9fa;
  height: 400px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.printDropdowns {
  display: flex;
  flex-wrap: wrap;
  margin-left: 250px;
  justify-content: space-between;
}
/* Marketing Videos Page */
.marketingVideosPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* LinkedIn Gallery Page */
.linkedInGalleryPage {
  padding: 50px;
  background-color: #efefef;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: max-content;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.linkedInHeaders {
  width: 23%;
}

/* Facebook Gallery Page */
.facebookGalleryPage {
  padding: 50px;
  text-align: center;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Instagram Gallery Page */
.instagramGalleryPage {
  padding: 50px;
  text-align: center;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Overcoming Objections page */
.overcomingObjectionsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
  flex-direction: row;
}
.overcomingObjectionsBtns {
  width: 60%;
}
/* Building my Business Page */
.buildingBusinessPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.buildingHeaders {
  width: 23%;
}
/* Sales Videos Page */
.salesVideoPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Marketing Videos Page */
.marketingVideosPage {
  text-align: center;
  background-color: #fbfbfb;
  border-radius: 15px;
  padding: 50px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Traditional Mortgages Page */
.traditionalPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Non-Traditional Mortgage */
.nonTraditionalPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.nonTraditionalBtns {
  width: 60%;
}
/* Mortgage Videos */
.mortgageVideosPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Loan Originator Software Page */
.loanOriginatorPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Pricing Engine Page */
.pricingEnginePage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* CRM Page */
.CRMPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* FAQ Page */
.FAQPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.faqContainer {
  margin: 60px 60px 60px 60px;
}
/* How to Submit Payroll Page */
.payrollSubmitPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.payrollSubmitContainer {
  margin: 60px 60px 60px 60px;
}
/* HR Videos Page */
.hrVideosPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Worksheets Page */
.worksheetsPage {
  padding: 50px;
  height: 400px;
  background-color: #f8f9fa;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  padding-right: 500px;
}

/* Purchase Tools Page */
.purchaseToolsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  flex-direction: row;
  justify-content: space-between;
}
.tools {
  width: 80%;
  display: flex;
  flex-direction: row;
}
/* Tools Videos Page */
.toolsVideosPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Investors Contact */
.investorsContactPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.contactSheets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 5%;
  padding-bottom: 5%;
}
/* Credit Score Boost */
.creditBoostPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}

/* Guidelines Page */
.guidelinesPage {
  padding: 50px;
  height: 800px;
  background-color: #f8f9fa;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.guidelineDropdowns {
  display: flex;
  margin-right: 100px;
}
/* Top Investors Page */
.topInvestorsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
.topInvestorHeaders {
  width: 30%;
}
/* Common Qualifying Page */
.commonQualifyingPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  text-align: left;
  flex-direction: column;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}

/* Conventional Page */
.conventionalPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}

/* Government Products Page */
.govProductsPage {
  padding: 50px;
  background-color: #f4f4f4;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.govProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 50px;
}
/* Jumbo Page */
.jumboPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  padding-right: 30%;
}
/* Commercial Loans */
.commercialLoansPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Bridge Loans */
.bridgeLoansPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Foreign Nationals */
.foreignNationalsPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* 40 YR Interest Only */
.yr40Page {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 100px;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* DSCR Loan */
.dscrLoanPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Bank Statement Loan */
.bankStatementPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Facebook Posts Page */
.facebookPostsPage {
  padding: 50px;
  background-color: #efefef;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: 1000px;
}
/* Facebook Cover Photos */
.coverPhotosPage {
  padding: 50px;
  background-color: #efefef;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: 400px;
}
/* Instagram Posts */
.instagramPostsPage {
  padding: 50px;
  background-color: #efefef;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Instagram Highlight Cover Images */
.highlightCoversPage {
  padding: 50px;
  background-color: #efefef;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: 1000px;
}
/* LinkedIn Posts */
.linkedinPosts {
  padding: 50px;
  background-color: #efefef;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: 1000px;
}
/* LinkedIn Banners */
.linkedinBanners{
  padding: 50px;
  background-color: #efefef;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  height: 1000px;
}
/* Hypotec Store Page */
.hypotecStorePage {
  padding: 50px;
  background-color: #f4f4f4;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  display: flex;
  height: 400px;
}
.storeBtns {
  text-align: center;
  padding-left: 20%;
}
/* Client Gifts Page */
.clientGiftsPage {
  padding: 50px;
  text-align: center;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  margin: 100px 0 100px 0;
  justify-content: space-between;
}
/* Benefits Page */
.benefitsPage {
  padding: 50px;
  text-align: center;
  background-color: #f4f4f4;
  display: flex;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.benefitsBtns {
  width: 80%;
}
/* Required Page */
.requiredPage {
  padding: 50px;
  text-align: center;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.requiredHeaders {
  width: 23%;
}
.requiredPage .navbar-collapse .navbar {
  display: none;
}
/* Compliance Page */
.compliancePage {
  padding: 50px;
  background-color: #f4f4f4;
  justify-content: space-between;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* Marketing Compliance Page */
.marketingCompliancePage {
  padding: 50px;
  background-color: #f4f4f4;
  justify-content: space-between;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
.marketingCompliancePDFs {
  padding-left: 25%;
}
/* Preferred Vendors Page */
.vendorsPage {
  padding: 50px;
  background-color: #f4f4f4;
  justify-content: space-between;
  border-radius: 15px;
  margin: 100px 0 100px 0;
}
/* No Income No Employment Loan */
.noIncomeLoanPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  border-radius: 15px;
  justify-content: space-between;
  margin: 100px 0 100px 0;
  padding-right: 300px;
}
/* Founders Message Page */
.foundersMessagePage {
  padding: 50px;
  background-color: #fdfdfd;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}
.founderImg {
  padding: 95px 0 0 100px;
}
.message {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 100px 100px 100px 0px;
}
/* Loan Officer Benefits Page */
.LObenefitsPage {
  background-color: hsl(0, 0%, 96%);
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;
}
.benefitsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 100px 100px 0 0;
}
.blueSection {
  display: flex;
  flex-direction: row;
  background-color: #0071e3;
  color: white;
}
.blueSectionComp {
  display: flex;
  flex-wrap: wrap;
  background-color: #0071e3;
  color: white;
}
.compHeaders {
  display: flex;
  width: 50%;
  padding: 50px;
  color: white;
}
.oldSection {
  padding-left: 200px;
  width: 45%;
}
.newSection {
  padding-left: 200px;
  width: 45%;
}
.increaseSection {
  padding: 50px 50px 50px 510px;
  text-align: center;
  font-weight: bold;
}
/* Get Licensed */
.licensingPage {
  padding: 50px;
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.licensingHeaders {
  text-align: center;
  padding: 0 0 5% 25%;
}
.container {
  position: relative;
  width: 250px !important;
  border: none;
}
.licensingContainer {
  text-align: left;
  padding-left: 100px !important;
  width: 100% !important;
}
.privacyContainer {
  width: 100% !important;
}
.longContainer {
  position: relative;
  width: 425px !important;
  border: none;
}
.medContainer {
  position: relative;
  width: 315px !important;
  border: none;
}
.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  text-align: center;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%) !important;
}
.medContainer:hover .image,
.longContainer:hover .image,
.container:hover .image {
  opacity: 0.3;
}
.medContainer:hover .middle,
.longContainer:hover .middle,
.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #009ee5;
  color: white;
  font-size: 16px;
  text-align: center;
  padding: 16px 16px;
  margin-right: 15px;
}
.signUpContainer {
  background-color: #f6fcfe;
  padding: 25px;
  margin: 25px 0px 25px 400px;
  border-radius: 15px;
  width: 45% !important;
}
.commonContainer {
  width: 90% !important;
  margin-top: 3%;
  border-bottom: #393939 solid 1px;
}
@media only screen and (min-device-width: 280px) and (max-device-width: 863px) {
  .leftDots {
    display: none;
  }
  .rightDots {
    display: none;
  }
  .logo {
    width: 170px;
    padding: 0 10px 0 10px;
  }
  .joinTeam {
    margin-right: 5px;
  }
  .joinTeam .btn {
    font-size: x-small;
  }
  .instagramGalleryPage,
  .facebookGalleryPage,
  .linkedInGalleryPage,
  .FAQPage,
  .payrollSubmitPage {
    padding-left: 10px;
    margin-left: 10px;
    width: 100% !important;
  }
  .contactUsPage {
    padding: 0;
  }
  .navbar-toggler {
    margin-left: 3%;
    border: none !important;
    box-shadow: none !important;
  }
  .navbar {
    flex-direction: row !important;
    align-items: baseline !important;
    flex-wrap: wrap !important;
  }
  .navbar-collapse {
    flex-direction: column;
    outline: none;
    margin: 0;
  }
  .LObenefitsPage,
  .benefitsImgs,
  .benefits,
  .blueSection,
  .compHeaders,
  .blueSectionComp {
    width: 100% !important;
    flex-direction: column;
  }
  .oldSection,
  .newSection,
  .blueSectionComp h3 {
    width: 100% !important;
    flex-direction: column;
    padding-left: 50px;
  }
  .contactUs,
  .stateLicensingPage,
  .message,
  .LObenefitsPage img,
  .linkedInHeaders,
  .linkedInGalleryPage button,
  .gallery img,
  .govProducts,
  .buttonDownload,
  .compliancePage video,
  .requiredPage video,
  .requiredHeaders,
  .homePage video,
  .salesVideoPage video,
  .marketingVideosPage video,
  .mortgageVideosPage video,
  .buildingHeaders,
  .buildingBusinessPage video,
  .hrVideosPage video,
  .CRMPage video,
  .loanOriginatorPage video,
  .pricingEnginePage video,
  .benefitsPage img,
  .benefitsBtns,
  .marketingHeaders,
  .resourceHeaders,
  .overcomingObjectionsBtns,
  .welcomeHeaders,
  .loginContainer,
  .loginContainer form,
  .loginButton {
    width: 100% !important;
  }
  .marketingCompliancePDFs,
  .signUpContainer {
    width: 100% !important;
    padding-left: 0;
    margin-left: 10px;
  }
  .licensingPage,
  .licensingContainer,
  .licensingHeaders,
  .message {
    width: 100% !important;
    padding: 10px !important;
  }
  .mapContainer img {
    width: 110%;
    padding: 50px;
  }
  .contactusImage {
    width: 80%;
    margin: 10%;
  }
  .contactUsContainer,
  .mapContainer {
    width: 100% !important;
    flex-direction: column;
  }
  .privacyPolicyPage {
    width: 100% !important;
    padding: 0;
  }
  .digitalDesignTempsPage button {
    margin-left: 0;
  }
  .employeePage {
    flex-direction: column;
  }
  .printDesignTempsPage {
    height: 1000px;
    flex-direction: column;
  }
  .printDropdowns {
    margin-left: 0;
  }
  .storeBtns {
    margin-left: -30%;
  }
  .articlesPage {
    flex-direction: column;
    width: 100%;
  }
  .tools,
  .purchaseToolsPage {
    flex-direction: column;
  }
  .worksheetsPage {
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
    height: 500px;
    justify-content: start;
  }
  .guidelineDropdowns,
  .guidelinesPage {
    justify-content: start;
    flex-direction: column;
    flex-wrap: inherit;
    height: 1000px;
  }
  .founderImg {
    padding: 0;
  }
  .navBar,
  .centerHeader {
    flex-direction: row;
    align-items: flex-start !important;
  }
  .coverPhotosPage,
  .facebookPostsPage,
  .highlightCoversPage,
  .marketingPage,
  .overcomingObjectionsPage,
  .hypotecStorePage,
  .resourcePage {
    height: max-content;
    display: block;
  }
  .topInvestorsPage,
  .topInvestorHeaders {
    width: 100% !important;
  }
}
